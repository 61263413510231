import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HotelSoapText from "../svg/hotelsoaptext.svg"

const Header = ({ siteTitle }) => (
  <header
    className="main-header"
    data-scroll
    data-scroll-sticky
    data-scroll-target="#container"
  >
    <div className="header">
      <div>
        <h1>
          <Link to="/" >
            <HotelSoapText />
          </Link>
        </h1>
        <div className="tag">streets & mountain - noisey car club & lazy hiking team</div>
        <a href="http://instagram.com/goodmorn.ng" target="_blank">insta</a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
